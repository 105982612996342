import request from "superagent"
import { queryToString } from "app/shared/util"
import swal from "sweetalert"
import { ClearDropDowns } from "app/shared/dropdown/actions"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import Datetime from "../../../shared/dateutil/index"
export const LOAD_ABDATUM = "LOAD_ABDATUM"
export const UPDATE_ABDATUM = "UPDATE_ABDATUM"
export const LOAD_PSTAMM = "LOAD_PERSONALSTAMM"
export const UPDATE_PSTAMM = "UPDATE_PERSONALSTAMM"
export const INSERT_PSTAMMABDATUM = "INSERT_PSTAMMABDATUM"
export const ADD_PSTAMMABDATUM = "ADD_PSTAMMABDATUM"
export const CREATE_PSTAMM_ABDATUM = "CREATE_PSTAMM_ABDATUM"
export const EDIT_PSTAMM = "EDIT_PSTAMM"
export const LOAD_ADRSSTAMM = "LOAD_ADRSSTAMM"
export const UPDATE_ADRSSTAMM = "UPDATE_ADRSSTAMM"
export const EDIT_ADRSSTAMM = "EDIT_ADRSSTAMM"
export const LOAD_ABKOSTAMM = "LOAD_ABKOSTAMM"
export const UPDATE_ABKOSTAMM = "UPDATE_ABKOSTAMM"
export const EDIT_ABKOSTAMM = "EDIT_ABKOSTAMM"
export const INSERT_ABKOSTAMM = "INSERT_ABKOSTAMM"
export const ADD_ABKOSTAMM_ROW = "ADD_ABKOSTAMM_ROW"
export const DELETE_ABKOSTAMM_ROW = "DELETE_ABKOSTAMM_ROW"
export const LOAD_INFOSTAMM = "LOAD_INFOSTAMM"
export const UPDATE_INFOSTAMM = "UPDATE_INFOSTAMM"
export const EDIT_INFOSTAMM = "EDIT_INFOSTAMM"
export const LOAD_INFOSTAMMDEF = "LOAD_INFOSTAMMDEF"
export const LOAD_GEKLASTAMM = "LOAD_GEKLASTAMM"
export const UPDATE_GEKLASTAMM = "UPDATE_GEKLASTAMM"
export const EDIT_GEKLASTAMM = "EDIT_GEKLASTAMM"
export const INSERT_GEKLASTAMM = "INSERT_GEKLASTAMM"
export const ADD_GEKLASTAMM_ROW = "ADD_GEKLASTAMM_ROW"
export const DELETE_GEKLASTAMM_ROW = "DELETE_GEKLASTAMM_ROW"
export const LOAD_AKTESTAMM = "LOAD_AKTESTAMM"
export const UPDATE_AKTESTAMM = "UPDATE_AKTESTAMM"
export const EDIT_AKTESTAMM = "EDIT_AKTESTAMM"
export const INSERT_AKTESTAMM = "INSERT_AKTESTAMM"
export const ADD_AKTESTAMM_ROW = "ADD_AKTESTAMM_ROW"
export const LOAD_SBHISTAMM = "LOAD_SBHISTAMM"
export const UPDATE_SBHISTAMM = "UPDATE_SBHISTAMM"
export const EDIT_SBHISTAMM = "EDIT_SBHISTAMM"
export const INSERT_SBHISTAMM = "INSERT_SBHISTAMM"
export const ADD_SBHISTAMM_ROW = "ADD_SBHISTAMM_ROW"
export const DELETE_SBHISTAMM_ROW = "DELETE_SBHISTAMM_ROW"
export const LOAD_QUALI = "LOAD_QUALI"
export const UPDATE_QUALI = "UPDATE_QUALI"
export const EDIT_QUALI = "EDIT_QUALI"
export const INSERT_QUALI = "INSERT_QUALI"
export const ADD_QUALI_ROW = "ADD_QUALI_ROW"
export const DELETE_QUALI_ROW = "DELETE_QUALI_ROW"
export const LOAD_ABKOKOMB = "LOAD_ABKOKOMB"
export const LOAD_PZUORD = "LOAD_PZUORD"
export const LOAD_PZUORDVONBIS = "LOAD_PZUORDVONBIS"
export const EDIT_PZUORD = "EDIT_PZUORD"
export const EDIT_PZUORDVONBIS = "EDIT_PZUORDVONBIS"
export const UPDATE_PZUORD = "UPDATE_PZUORD"
export const UPDATE_PZUORDVONBIS = "UPDATE_PZUORDVONBIS"
export const ADD_PZUORD_ROW = "ADD_PZUORD_ROW"
export const DELETE_PZUORD_ROW = "DELETE_PZUORD_ROW"
export const DELETED_PZUORD_ROW = "DELETED_PZUORD_ROW"
export const CHANGE_AB_DATUM = "CHANGE_AB_DATUM"
export const CREATE_NEW_AB_DATUM = "CREATE_NEW_AB_DATUM"
export const LOAD_PFLICHT = "LOAD_PFLICHTFELDER"
export const ABTEILUNGS_KOMBI = "ABTEILUNGS_KOMBI"
export const LOAD_INFOBENUNUM = "LOAD_INFOBENUNUM"
export const UPDATE_INFOBENUNUM = "UPDATE_INFOBENUNUM"
export const EDIT_BENUNUM = "EDIT_BENUNUM"
export const LOAD_INFOBENUCHA = "LOAD_INFOBENUCHA"
export const UPDATE_INFOBENUCHA = "UPDATE_INFOBENUCHA"
export const EDIT_BENUCHA = "EDIT_BENUCHA"
export const LOAD_INFOBENUNUMDEF = "LOAD_INFOBENUNUMDEF"
export const LOAD_INFOBENUCHADEF = "LOAD_INFOBENUCHADEF"
export const LOAD_PZVVA = "LOAD_PZVVA"
export const EDIT_PZVVA = "EDIT_PZVVA"
export const EDIT_PZVVA_TGL = "EDIT_PZVVA_TGL"
export const ADD_PZVVA_ROW = "ADD_PZVVA_ROW"
export const UPDATE_PZVVA = "UPDATE_PZVVA"
export const DELETE_PZVVA_ROW = "DELETE_PZVVA_ROW"
export const DELETED_PZVVA_ROW = "DELETED_PZVVA_ROW"
export const DELETED_SOLL_RECORDS = "DELETED_SOLL_RECORDS"

export function DeleteSollRecordByIntIDSuccess(datum, int_id, data) {
  return {
    type: DELETED_SOLL_RECORDS,
    status: SUCCESS,
    datum,
    int_id,
    data
  }
}

export function AddPZVVARow(int_id, ab_datum, values) {
  return {
    type: ADD_PZVVA_ROW,
    int_id,
    ab_datum,
    values
  }
}
function CheckPZVVA(pzvva) {
  if (
    pzvva.bis_dat &&
    Datetime.fromISO(pzvva.bis_dat) < Datetime.fromISO(pzvva.ab_datum)
  ) {
    return "Das Bis-Datum muss nach dem Ab-Datum liegen !"
  }
  if (pzvva.tage_wo > 7) {
    return "bitte Tagewoche nicht mehr als 7 Tage angeben"
  }
  if (pzvva.arb_zt_tgl > 24) {
    return "bitte nicht mehr als 24 Stunden angeben"
  }
  // if (pzvva.arb_zt_mo == "NaN") {
  //   return "bitte mindestens 0 Stunden angeben"
  // }
  // if (pzvva.arb_zt_di == "NaN") {
  //   return "bitte mindestens 0 Stunden angeben"
  // }
  // if (pzvva.arb_zt_mi == "NaN") {
  //   return "bitte mindestens 0 Stunden angeben"
  // }
  // if (pzvva.arb_zt_do == "NaN") {
  //   return "bitte mindestens 0 Stunden angeben"
  // }
  // if (pzvva.arb_zt_fr == "NaN") {
  //   return "bitte mindestens 0 Stunden angeben"
  // }
  // if (pzvva.arb_zt_sa == "NaN") {
  //   return "bitte mindestens 0 Stunden angeben"
  // }
  // if (pzvva.arb_zt_so == "NaN") {
  //   return "bitte mindestens 0 Stunden angeben"
  // }
  if (pzvva.arb_zt_mo > 24) {
    return "bitte nicht mehr als 24 Stunden angeben"
  }
  if (pzvva.arb_zt_di > 24) {
    return "bitte nicht mehr als 24 Stunden angeben"
  }
  if (pzvva.arb_zt_mi > 24) {
    return "bitte nicht mehr als 24 Stunden angeben"
  }
  if (pzvva.arb_zt_do > 24) {
    return "bitte nicht mehr als 24 Stunden angeben"
  }
  if (pzvva.arb_zt_fr > 24) {
    return "bitte nicht mehr als 24 Stunden angeben"
  }
  if (pzvva.arb_zt_sa > 24) {
    return "bitte nicht mehr als 24 Stunden angeben"
  }
  if (pzvva.arb_zt_so > 24) {
    return "bitte nicht mehr als 24 Stunden angeben"
  }
  // }
  return null
}

export function UpdatePZVVASuccess(int_id, id, data) {
  return {
    type: UPDATE_PZVVA,
    status: SUCCESS,
    int_id,
    id,
    data
  }
}

function getPzVVADirtyRows(state) {
  let pzvva = state.Personalstamm.pzvva
  let result = []
  for (let rows of Object.values(pzvva)) {
    for (let row of rows) {
      if (row.__dirty || row.__new || row.__deleted) {
        result.push(row)
      }
    }
  }
  return result
}

export function UpdatePZVVA() {
  return (dispatch, getState) => {
    let pzvva = getPzVVADirtyRows(getState())
    for (let row of pzvva) {
      var err = CheckPZVVA(row)
      if (err !== null) {
        swal({
          title: "Hinweis",
          text: err,
          type: "warning",
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Ok!",
          closeOnConfirm: false
        })
        continue
      }
      if (row.__deleted && !row.__new) {
        request
          .delete(`/api/pzvva/${row.id}`)
          .send(row)
          .end((err, response) => {
            dispatch(DeletePZVVASuccess(row.int_id, row.id, response.body))
          })
        continue
      }
      if (row.__new) {
        let { id, ...r } = row
        request
          .post("/api/pzvva")
          .send(r)
          .end((err, response) => {
            dispatch(UpdatePZVVASuccess(row.int_id, row.id, response.body))
          })
        continue
      }
      if (row.__dirty && !row.__new) {
        request
          .put("/api/pzvva")
          .send(row)
          .end((err, response) => {
            dispatch(UpdatePZVVASuccess(row.int_id, row.id, response.body))
          })
        continue
      }
    }
  }
}

export function PZVVAUnsavedChanges(state) {
  for (let pzvva of Object.values(state)) {
    for (let row of pzvva) {
      if (row.__new || row.__dirty || row.__deleted) {
        return true
      }
    }
  }
  return false
}

export function DeletePZVVASuccess(int_id, ab_datum, data) {
  return {
    type: DELETED_PZVVA_ROW,
    status: SUCCESS,
    int_id,
    ab_datum,
    data
  }
}

export function DeletePZVVARow(int_id, ab_datum) {
  return {
    type: DELETE_PZVVA_ROW,
    int_id,
    ab_datum
  }
}

export function EditPZVVA(int_id, id, changes) {
  return {
    type: EDIT_PZVVA,
    int_id,
    id,
    changes
  }
}
export function EditPZVVATgl(int_id, id, changes) {
  return {
    type: EDIT_PZVVA_TGL,
    int_id,
    id,
    changes
  }
}

export function LoadPzVVAStarted(int_id) {
  return {
    type: LOAD_PZVVA,
    status: STARTED,
    int_id
  }
}
export function LoadPzVVASuccess(int_id, data) {
  return {
    type: LOAD_PZVVA,
    status: SUCCESS,
    int_id,
    data
  }
}
export function LoadPzVVA(int_id) {
  return (dispatch, getState) => {
    let data = getState().Personalstamm.pzvva[int_id]
    if (data) {
      return
    }
    dispatch(LoadPzVVAStarted(int_id))
    request.get(`/api/p/${int_id}/pzvva`).end((err, response) => {
      dispatch(LoadPzVVASuccess(int_id, response.body))
    })
  }
}

export function EditInfoBenucha(int_id, benc_id, changes) {
  return {
    type: EDIT_BENUCHA,
    int_id,
    benc_id,
    changes
  }
}

export function LoadInfoBenuchaSuccess(int_id, data) {
  return {
    type: LOAD_INFOBENUCHA,
    status: SUCCESS,
    int_id,
    data
  }
}

export function LoadInfoBenucha(int_id) {
  return (dispatch, getState) => {
    let data = getState().Personalstamm.infobenucha[int_id]
    if (data) {
      return
    }
    request.get(`/api/p/${int_id}/infobenucha`).end((err, response) => {
      dispatch(LoadInfoBenuchaSuccess(int_id, response.body))
    })
  }
}

export function UpdateInfoBenuchaSuccess(int_id, data) {
  return {
    type: UPDATE_INFOBENUCHA,
    status: SUCCESS,
    int_id,
    data
  }
}

export function UpdateInfoBenucha(int_id) {
  return (dispatch, getState) => {
    let infobenuchaData = getState().Personalstamm.infobenucha[int_id] || []
    for (let row of infobenuchaData) {
      if (row.__new) {
        let { id, ...r } = row
        request
          .post(`/api/p/${int_id}/infobenucha`)
          .send(r)
          .end((err, response) => {
            dispatch(UpdateInfoBenuchaSuccess(int_id, response.body))
          })
      } else if (row.__dirty) {
        request
          .put(`/api/p/${int_id}/infobenucha`)
          .send(row)
          .end((err, response) => {
            dispatch(UpdateInfoBenuchaSuccess(int_id, response.body))
          })
      }
    }
  }
}
export function LoadInfoBenuchaDefSuccess(data) {
  return {
    type: LOAD_INFOBENUCHADEF,
    status: SUCCESS,
    data
  }
}
export function LoadInfoBenuchaDef() {
  return (dispatch, getState) => {
    request.get("/api/infobenucha/def").end((err, response) => {
      dispatch(LoadInfoBenuchaDefSuccess(response.body))
    })
  }
}

export function EditInfoBenunum(int_id, benn_id, changes) {
  return {
    type: EDIT_BENUNUM,
    int_id,
    benn_id,
    changes
  }
}

export function LoadInfoBenunumSuccess(int_id, data) {
  return {
    type: LOAD_INFOBENUNUM,
    status: SUCCESS,
    int_id,
    data
  }
}
export function LoadInfoBenunum(int_id) {
  return (dispatch, getState) => {
    let data = getState().Personalstamm.infobenunum[int_id]
    if (data) {
      return
    }
    request.get(`/api/p/${int_id}/infobenunum`).end((err, response) => {
      dispatch(LoadInfoBenunumSuccess(int_id, response.body))
    })
  }
}

export function UpdateInfoBenunumSuccess(int_id, data) {
  return {
    type: UPDATE_INFOBENUNUM,
    status: SUCCESS,
    int_id,
    data
  }
}
export function UpdateInfoBenunum(int_id) {
  return (dispatch, getState) => {
    let infobenunumData = getState().Personalstamm.infobenunum[int_id] || []
    for (let row of infobenunumData) {
      if (row.__new) {
        let { id, ...r } = row
        request
          .post(`/api/p/${int_id}/infobenunum`)
          .send(r)
          .end((err, response) => {
            dispatch(UpdateInfoBenunumSuccess(int_id, response.body))
          })
      } else if (row.__dirty && !row.__new) {
        // if (row.__dirty) {
        request
          .put(`/api/p/${int_id}/infobenunum`)
          .send(row)
          .end((err, response) => {
            dispatch(UpdateInfoBenunumSuccess(int_id, response.body))
          })
      }
    }
  }
}
export function LoadInfoBenunumDefSuccess(data) {
  return {
    type: LOAD_INFOBENUNUMDEF,
    status: SUCCESS,
    data
  }
}
export function LoadInfoBenunumDef() {
  return (dispatch, getState) => {
    request.get("/api/infobenunum/def").end((err, response) => {
      dispatch(LoadInfoBenunumDefSuccess(response.body))
    })
  }
}

export function LoadPflichtStarted() {
  return {
    type: LOAD_PFLICHT,
    status: STARTED
  }
}

export function LoadPflichtSuccess(data) {
  return {
    type: LOAD_PFLICHT,
    status: SUCCESS,
    data
  }
}

export function LoadPflichtError(error) {
  return {
    type: LOAD_PFLICHT,
    status: ERROR,
    error
  }
}

export function changeAbDatumPlain(Datum) {
  return {
    type: CHANGE_AB_DATUM,
    Datum
  }
}

export function createNewAbDatum(Datum, IntID) {
  return {
    type: CREATE_NEW_AB_DATUM,
    Datum,
    IntID
  }
}

export function changeAbDatum(Datum, IntID) {
  return (dispatch, getState) => {
    let pstaemme = getState().Personalstamm.data[IntID]
    if (pstaemme[Datum]) {
      dispatch(changeAbDatumPlain(Datum))
    } else {
      dispatch(createNewAbDatum(Datum, IntID))
    }
  }
}

export function AddGeklaStammRow(int_id, num_key) {
  return {
    type: ADD_GEKLASTAMM_ROW,
    int_id,
    num_key
  }
}
export function DeleteGeklaStammRow(int_id, num_key) {
  return {
    type: DELETE_GEKLASTAMM_ROW,
    int_id,
    num_key
  }
}
export function DeleteSbhiStammRow(int_id, ab_datum) {
  return {
    type: DELETE_SBHISTAMM_ROW,
    int_id,
    ab_datum
  }
}
export function DeleteAbkoStammRow(int_id, ab_datum, l_nbr) {
  return {
    type: DELETE_ABKOSTAMM_ROW,
    int_id,
    ab_datum,
    l_nbr
  }
}
export function DeletePzuordRow(int_id, ab_datum) {
  return {
    type: DELETE_PZUORD_ROW,
    int_id,
    ab_datum
  }
}
export function AddPzuordRow(int_id, ab_datum, values) {
  return {
    type: ADD_PZUORD_ROW,
    int_id,
    ab_datum,
    values
  }
}
export function AddQualiRow(int_id) {
  return {
    type: ADD_QUALI_ROW,
    int_id
  }
}
export function DeleteQualiRow(int_id, id) {
  return {
    type: DELETE_QUALI_ROW,
    int_id,
    id
  }
}

export function AddAbkoStammRow(int_id, ab_datum) {
  return {
    type: ADD_ABKOSTAMM_ROW,
    int_id,
    ab_datum
  }
}

export function AddPstammAbDatumPlain(Datum) {
  return {
    type: ADD_PSTAMMABDATUM,
    Datum
  }
}
export function createNewPstammAbDatum(Datum, IntID) {
  return {
    type: CREATE_PSTAMM_ABDATUM,
    Datum,
    IntID
  }
}
export function AddPstammAbDatum(Datum, IntID) {
  return (dispatch, getState) => {
    let pstamm = getState().Personalstamm.data[IntID]
    // hier int_id neu ???
    if (pstamm[Datum]) {
      dispatch(AddPstammAbDatumPlain(Datum))
    } else {
      dispatch(createNewPstammAbDatum(Datum, IntID))
    }
  }
}

export function AddAktestammRow(int_id) {
  return {
    type: ADD_AKTESTAMM_ROW,
    int_id
  }
}

export function AddSbhistammRow(int_id) {
  return {
    type: ADD_SBHISTAMM_ROW,
    int_id
  }
}

export function EditPstamm(int_id, ab_datum, changes) {
  return {
    type: EDIT_PSTAMM,
    int_id,
    ab_datum,
    changes
  }
}

export function EditAbkostamm(int_id, ab_datum, l_nbr, changes) {
  return {
    type: EDIT_ABKOSTAMM,
    int_id,
    ab_datum,
    l_nbr,
    changes
  }
}

export function AbteilungsKombiStarted() {
  return {
    type: ABTEILUNGS_KOMBI,
    status: STARTED
  }
}

export function AbteilungsKombiSuccess(data) {
  return {
    type: ABTEILUNGS_KOMBI,
    status: SUCCESS,
    data
  }
}

export function AbteilungsKombiError(error) {
  return {
    type: ABTEILUNGS_KOMBI,
    status: ERROR,
    error
  }
}

export function AbteilungsKombi() {
  return (dispatch) => {
    dispatch(AbteilungsKombiStarted())
    request.get("/api/parameter/abteilung/kombination").end((err, response) => {
      if (err) {
        dispatch(AbteilungsKombiError(err))
      } else {
        dispatch(AbteilungsKombiSuccess(response.body))
      }
    })
  }
}

export function GetAbtFromKomb(partialAbt, abtKomb) {
  for (let row of abtKomb) {
    if (row.u_abt_id == partialAbt.u_abt_id) {
      partialAbt.abt_id = row.abt_id
      partialAbt.h_abt_id = row.h_abt_id
      break
    }
    if (row.abt_id == partialAbt.abt_id) {
      partialAbt.h_abt_id = row.h_abt_id
      partialAbt.u_abt_id = null
      break
    }
  }
  return partialAbt
}

export function EditAbkostammUAbt(int_id, ab_datum, l_nbr, UAbtID) {
  return (dispatch, getState) => {
    //let changes = { u_abt_id: UAbtID }
    let abtKomb = getState().Personalstamm.abkokombdata
    let changes = GetAbtFromKomb({ u_abt_id: UAbtID }, abtKomb)
    // for (let row of abtKomb) {
    //   if (row.u_abt_id == UAbtID) {
    //     changes.abt_id = row.abt_id
    //     changes.h_abt_id = row.h_abt_id
    //     break
    //   }
    // }
    dispatch(EditAbkostamm(int_id, ab_datum, l_nbr, changes))
  }
}
export function EditAbkostammAbt(int_id, ab_datum, l_nbr, AbtID) {
  return (dispatch, getState) => {
    let abtKomb = getState().Personalstamm.abkokombdata
    let changes = GetAbtFromKomb({ abt_id: AbtID }, abtKomb)
    // for (let row of abtKomb) {
    //   if (row.abt_id == AbtID) {
    //     changes.h_abt_id = row.h_abt_id
    //     break
    //   }
    // }
    dispatch(EditAbkostamm(int_id, ab_datum, l_nbr, changes))
  }
}

export function EditPzuord(int_id, ab_datum, changes) {
  return {
    type: EDIT_PZUORD,
    int_id,
    ab_datum,
    changes
  }
}

export function EditPzuordVonBis(int_id, day, changes, datum) {
  return {
    type: EDIT_PZUORDVONBIS,
    int_id,
    day,
    changes,
    datum
  }
}

export function EditInfostamm(int_id, ktkt_art, changes) {
  return {
    type: EDIT_INFOSTAMM,
    int_id,
    ktkt_art,
    changes
  }
}

export function EditAdrsstamm(int_id, adrs_id, changes) {
  return {
    type: EDIT_ADRSSTAMM,
    int_id,
    adrs_id,
    changes
  }
}

export function EditGeklastamm(int_id, num_key, changes) {
  return {
    type: EDIT_GEKLASTAMM,
    int_id,
    num_key,
    changes
  }
}
export function EditQuali(int_id, id, changes) {
  return {
    type: EDIT_QUALI,
    int_id,
    id,
    changes
  }
}
export function EditSbhistamm(int_id, ab_datum, changes) {
  return {
    type: EDIT_SBHISTAMM,
    int_id,
    ab_datum,
    changes
  }
}
export function EditAktestamm(int_id, doc_id, changes) {
  return {
    type: EDIT_AKTESTAMM,
    int_id,
    doc_id,
    changes
  }
}
export function LoadAbDatumStarted(datum) {
  return {
    type: LOAD_ABDATUM,
    status: STARTED,
    datum
  }
}

export function LoadAbDatumSuccess(datum, data) {
  return {
    type: LOAD_ABDATUM,
    status: SUCCESS,
    datum,
    data
  }
}

export function LoadAbDatumError(datum, error) {
  return {
    type: LOAD_ABDATUM,
    status: ERROR,
    datum,
    error
  }
}

export function UpdateAbDatumStarted(datum) {
  return {
    type: UPDATE_ABDATUM,
    status: STARTED,
    datum
  }
}

export function UpdateAbDatumSuccess(datum, data) {
  return {
    type: UPDATE_ABDATUM,
    status: SUCCESS,
    datum,
    data
  }
}

export function UpdateAbDatumError(datum, error) {
  return {
    type: UPDATE_ABDATUM,
    status: ERROR,
    datum,
    error
  }
}

export function LoadPstammStarted(int_id) {
  return {
    type: LOAD_PSTAMM,
    status: STARTED,
    int_id
  }
}

export function LoadPstammSuccess(int_id, data) {
  return {
    type: LOAD_PSTAMM,
    status: SUCCESS,
    int_id,
    data
  }
}

export function LoadPstammError(int_id, error) {
  return {
    type: LOAD_PSTAMM,
    status: ERROR,
    int_id,
    error
  }
}

export function LoadAdrsstammStarted(int_id) {
  return {
    type: LOAD_ADRSSTAMM,
    status: STARTED,
    int_id
  }
}

export function LoadAdrsstammSuccess(int_id, data) {
  return {
    type: LOAD_ADRSSTAMM,
    status: SUCCESS,
    int_id,
    data
  }
}

export function LoadAdrsstammError(int_id, error) {
  return {
    type: LOAD_ADRSSTAMM,
    status: ERROR,
    int_id,
    error
  }
}
export function LoadAbkostammStarted(int_id) {
  return {
    type: LOAD_ABKOSTAMM,
    status: STARTED,
    int_id
  }
}
export function LoadPzuordStarted(int_id) {
  return {
    type: LOAD_PZUORD,
    status: STARTED,
    int_id
  }
}
export function LoadAbkoKombStarted(mand_id, firm_id) {
  return {
    type: LOAD_ABKOKOMB,
    status: STARTED,
    mand_id,
    firm_id
  }
}
export function LoadInfostammStarted(int_id) {
  return {
    type: LOAD_INFOSTAMM,
    status: STARTED,
    int_id
  }
}
export function LoadGeklastammStarted(int_id) {
  return {
    type: LOAD_GEKLASTAMM,
    status: STARTED,
    int_id
  }
}
export function LoadQualiStarted(int_id) {
  return {
    type: LOAD_QUALI,
    status: STARTED,
    int_id
  }
}
export function LoadSbhistammStarted(int_id) {
  return {
    type: LOAD_SBHISTAMM,
    status: STARTED,
    int_id
  }
}
export function LoadAktestammStarted(int_id) {
  return {
    type: LOAD_AKTESTAMM,
    status: STARTED,
    int_id
  }
}
export function LoadAbkostammSuccess(int_id, data) {
  return {
    type: LOAD_ABKOSTAMM,
    status: SUCCESS,
    int_id,
    data
  }
}
export function LoadPzuordSuccess(int_id, data) {
  return {
    type: LOAD_PZUORD,
    status: SUCCESS,
    int_id,
    data
  }
}
export function LoadAbkoKombSuccess(mand_id, firm_id, data) {
  return {
    type: LOAD_ABKOKOMB,
    status: SUCCESS,
    mand_id,
    firm_id,
    data
  }
}
export function LoadInfostammSuccess(int_id, data) {
  return {
    type: LOAD_INFOSTAMM,
    status: SUCCESS,
    int_id,
    data
  }
}
export function LoadGeklastammSuccess(int_id, data) {
  return {
    type: LOAD_GEKLASTAMM,
    status: SUCCESS,
    int_id,
    data
  }
}
export function LoadQualiSuccess(int_id, data) {
  return {
    type: LOAD_QUALI,
    status: SUCCESS,
    int_id,
    data
  }
}
export function LoadSbhistammSuccess(int_id, data) {
  return {
    type: LOAD_SBHISTAMM,
    status: SUCCESS,
    int_id,
    data
  }
}
export function LoadAktestammSuccess(int_id, data) {
  return {
    type: LOAD_AKTESTAMM,
    status: SUCCESS,
    int_id,
    data
  }
}
export function LoadAbkostammError(int_id, error) {
  return {
    type: LOAD_ABKOSTAMM,
    status: ERROR,
    int_id,
    error
  }
}
export function LoadPzuordError(int_id, error) {
  return {
    type: LOAD_PZUORD,
    status: ERROR,
    int_id,
    error
  }
}
export function LoadAbkoKombError(mand_id, firm_id, error) {
  return {
    type: LOAD_ABKOKOMB,
    status: ERROR,
    mand_id,
    firm_id,
    error
  }
}
export function LoadInfostammError(int_id, error) {
  return {
    type: LOAD_INFOSTAMM,
    status: ERROR,
    int_id,
    error
  }
}
export function LoadGeklastammError(int_id, error) {
  return {
    type: LOAD_GEKLASTAMM,
    status: ERROR,
    int_id,
    error
  }
}
export function LoadQualiError(int_id, error) {
  return {
    type: LOAD_QUALI,
    status: ERROR,
    int_id,
    error
  }
}
export function LoadSbhistammError(int_id, error) {
  return {
    type: LOAD_SBHISTAMM,
    status: ERROR,
    int_id,
    error
  }
}
export function LoadAktestammError(int_id, error) {
  return {
    type: LOAD_AKTESTAMM,
    status: ERROR,
    int_id,
    error
  }
}

export function UpdatePstammStarted(int_id, datum) {
  return {
    type: UPDATE_PSTAMM,
    status: STARTED,
    int_id,
    datum
  }
}

export function UpdatePstammSuccess(int_id, datum, data) {
  return {
    type: UPDATE_PSTAMM,
    status: SUCCESS,
    int_id,
    datum,
    data
  }
}

export function UpdatePstammError(int_id, datum, error) {
  return {
    type: UPDATE_PSTAMM,
    status: ERROR,
    int_id,
    datum,
    error
  }
}

export function UpdateAbkostammStarted(int_id, datum) {
  return {
    type: UPDATE_ABKOSTAMM,
    status: STARTED,
    int_id,
    datum
  }
}

export function UpdateAbkostammSuccess(int_id, datum, data) {
  return {
    type: UPDATE_ABKOSTAMM,
    status: SUCCESS,
    int_id,
    datum,
    data
  }
}

export function UpdateAbkostammError(int_id, datum, error) {
  return {
    type: UPDATE_ABKOSTAMM,
    status: ERROR,
    int_id,
    datum,
    error
  }
}
export function UpdateInfostammStarted(int_id) {
  return {
    type: UPDATE_INFOSTAMM,
    status: STARTED,
    int_id
  }
}

export function UpdateInfostammSuccess(int_id, data) {
  return {
    type: UPDATE_INFOSTAMM,
    status: SUCCESS,
    int_id,
    data
  }
}

export function UpdateInfostammError(int_id, error) {
  return {
    type: UPDATE_INFOSTAMM,
    status: ERROR,
    int_id,
    error
  }
}

export function UpdateGeklastammStarted(int_id) {
  return {
    type: UPDATE_GEKLASTAMM,
    status: STARTED,
    int_id
  }
}
export function UpdatePzuordStarted(int_id, ab_datum) {
  return {
    type: UPDATE_PZUORD,
    status: STARTED,
    int_id,
    ab_datum
  }
}

export function InsertGeklastammStarted(int_id) {
  return {
    type: INSERT_GEKLASTAMM,
    status: STARTED,
    int_id
  }
}
export function DeleteGeklastammStarted(int_id) {
  return {
    type: DELETE_GEKLASTAMM_ROW,
    status: STARTED,
    int_id
  }
}
export function DeleteAbkostammStarted(int_id) {
  return {
    type: DELETE_ABKOSTAMM_ROW,
    status: STARTED,
    int_id
  }
}
export function DeleteSbhistammStarted(int_id) {
  return {
    type: DELETE_SBHISTAMM_ROW,
    status: STARTED,
    int_id
  }
}
export function UpdateQualiStarted(int_id) {
  return {
    type: UPDATE_QUALI,
    status: STARTED,
    int_id
  }
}
export function InsertQualiStarted(int_id) {
  return {
    type: INSERT_QUALI,
    status: STARTED,
    int_id
  }
}
export function DeleteQualiStarted(int_id) {
  return {
    type: DELETE_QUALI_ROW,
    status: STARTED,
    int_id
  }
}

export function InsertPstammAbDatumStarted(int_id) {
  return {
    type: INSERT_PSTAMMABDATUM,
    status: STARTED,
    int_id
  }
}
export function InsertAbkostammStarted(int_id) {
  return {
    type: INSERT_ABKOSTAMM,
    status: STARTED,
    int_id
  }
}
export function InsertAktestammStarted(int_id) {
  return {
    type: INSERT_AKTESTAMM,
    status: STARTED,
    int_id
  }
}
export function InsertSbhistammStarted(int_id) {
  return {
    type: INSERT_SBHISTAMM,
    status: STARTED,
    int_id
  }
}
export function UpdateSbhistammStarted(int_id) {
  return {
    type: UPDATE_SBHISTAMM,
    status: STARTED,
    int_id
  }
}
export function UpdateAktestammStarted(int_id) {
  return {
    type: UPDATE_AKTESTAMM,
    status: STARTED,
    int_id
  }
}
export function UpdateGeklastammSuccess(int_id, data) {
  return {
    type: UPDATE_GEKLASTAMM,
    status: SUCCESS,
    int_id,
    data
  }
}
export function UpdatePzuordSuccess(int_id, ab_datum, data) {
  return {
    type: UPDATE_PZUORD,
    status: SUCCESS,
    int_id,
    ab_datum,
    data
  }
}
export function InsertPzuordSuccess(int_id, data) {
  return {
    type: ADD_PZUORD_ROW,
    status: SUCCESS,
    int_id,
    data
  }
}
export function DeletePzuordSuccess(int_id, ab_datum, data) {
  return {
    type: DELETED_PZUORD_ROW,
    status: SUCCESS,
    int_id,
    ab_datum,
    data
  }
}
export function InsertGeklastammSuccess(int_id, data) {
  return {
    type: INSERT_GEKLASTAMM,
    status: SUCCESS,
    int_id,
    data
  }
}
export function DeleteGeklastammSuccess(int_id, data) {
  return {
    type: DELETE_GEKLASTAMM_ROW,
    status: SUCCESS,
    int_id,
    data
  }
}
export function DeleteAbkostammSuccess(int_id, data) {
  return {
    type: DELETE_ABKOSTAMM_ROW,
    status: SUCCESS,
    int_id,
    data
  }
}
export function DeleteSbhistammSuccess(int_id, data) {
  return {
    type: DELETE_SBHISTAMM_ROW,
    status: SUCCESS,
    int_id,
    data
  }
}
export function UpdateQualiSuccess(int_id, data) {
  return {
    type: UPDATE_QUALI,
    status: SUCCESS,
    int_id,
    data
  }
}
export function InsertQualiSuccess(int_id, data) {
  return {
    type: INSERT_QUALI,
    status: SUCCESS,
    int_id,
    data
  }
}
export function DeleteQualiSuccess(int_id, data) {
  return {
    type: DELETE_QUALI_ROW,
    status: SUCCESS,
    int_id,
    data
  }
}

export function InsertPstammAbDatumSuccess(int_id, datum, data) {
  return {
    type: INSERT_PSTAMMABDATUM,
    status: SUCCESS,
    int_id,
    datum,
    data
  }
}
export function InsertAbkostammSuccess(int_id, data) {
  return {
    type: INSERT_ABKOSTAMM,
    status: SUCCESS,
    int_id,
    data
  }
}
export function InsertAktestammSuccess(int_id, data) {
  return {
    type: INSERT_AKTESTAMM,
    status: SUCCESS,
    int_id,
    data
  }
}
export function InsertSbhistammSuccess(int_id, data) {
  return {
    type: INSERT_SBHISTAMM,
    status: SUCCESS,
    int_id,
    data
  }
}
export function UpdateSbhistammSuccess(int_id, data) {
  return {
    type: UPDATE_SBHISTAMM,
    status: SUCCESS,
    int_id,
    data
  }
}
export function UpdateAktestammSuccess(int_id, data) {
  return {
    type: UPDATE_AKTESTAMM,
    status: SUCCESS,
    int_id,
    data
  }
}
export function UpdateGeklastammError(int_id, error) {
  return {
    type: UPDATE_GEKLASTAMM,
    status: ERROR,
    int_id,
    error
  }
}

// export function InsertPzuordError(int_id, error) {
//   return {
//     type: ADD_PZUORD_ROW,
//     status: ERROR,
//     int_id,
//     error
//   }
// }

export function InsertGeklastammError(int_id, error) {
  return {
    type: INSERT_GEKLASTAMM,
    status: ERROR,
    int_id,
    error
  }
}
export function DeleteGeklastammError(int_id, error) {
  return {
    type: DELETE_GEKLASTAMM_ROW,
    status: ERROR,
    int_id,
    error
  }
}
export function DeleteAbkostammError(int_id, error) {
  return {
    type: DELETE_ABKOSTAMM_ROW,
    status: ERROR,
    int_id,
    error
  }
}
export function DeleteSbhistammError(int_id, error) {
  return {
    type: DELETE_SBHISTAMM_ROW,
    status: ERROR,
    int_id,
    error
  }
}
export function UpdateQualiError(int_id, error) {
  return {
    type: UPDATE_QUALI,
    status: ERROR,
    int_id,
    error
  }
}
export function InsertQualiError(int_id, error) {
  return {
    type: INSERT_QUALI,
    status: ERROR,
    int_id,
    error
  }
}
export function DeleteQualiError(int_id, error) {
  return {
    type: DELETE_QUALI_ROW,
    status: ERROR,
    int_id,
    error
  }
}

export function InsertPstammAbDatumError(int_id, datum, error) {
  return {
    type: INSERT_PSTAMMABDATUM,
    status: ERROR,
    int_id,
    datum,
    error
  }
}
export function InsertAbkostammError(int_id, error) {
  return {
    type: INSERT_ABKOSTAMM,
    status: ERROR,
    int_id,
    error
  }
}
export function InsertAktestammError(int_id, error) {
  return {
    type: INSERT_AKTESTAMM,
    status: ERROR,
    int_id,
    error
  }
}
export function InsertSbhistammError(int_id, error) {
  return {
    type: INSERT_SBHISTAMM,
    status: ERROR,
    int_id,
    error
  }
}
export function UpdateSbhistammError(int_id, error) {
  return {
    type: UPDATE_SBHISTAMM,
    status: ERROR,
    int_id,
    error
  }
}
export function UpdateAktestammError(int_id, error) {
  return {
    type: UPDATE_AKTESTAMM,
    status: ERROR,
    int_id,
    error
  }
}

export function UpdateAdrsstammStarted(int_id) {
  return {
    type: UPDATE_ADRSSTAMM,
    status: STARTED,
    int_id
  }
}

export function UpdateAdrsstammSuccess(int_id, data) {
  return {
    type: UPDATE_ADRSSTAMM,
    status: SUCCESS,
    int_id,
    data
  }
}

export function UpdateAdrsstammError(int_id, error) {
  return {
    type: UPDATE_ADRSSTAMM,
    status: ERROR,
    int_id,
    error
  }
}

function getDirtyRows(state) {
  let pzuordData = state.Personalstamm.pzuorddata
  let result = []
  for (let rows of Object.values(pzuordData)) {
    for (let row of rows) {
      if (row.__dirty || row.__new || row.__deleted) {
        result.push(row)
      }
    }
  }
  return result
}

export function UpdatePzuord() {
  // export function UpdatePzuordAll() {
  return (dispatch, getState) => {
    //let pzuordData = getState().Personalstamm.pzuorddata
    let pzuordData = getDirtyRows(getState())
    for (let row of pzuordData) {
      let sperrdatum = getState().Fehlerprotokoll.sperrdatum[row.int_id] || []
      let nosperre = getState().Fehlerprotokoll.nosperre[row.int_id] || []
      var err = CheckPzuord(row, sperrdatum, nosperre)
      if (err !== null) {
        swal({
          title: "Hinweis",
          text: err,
          type: "warning",
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Ok!",
          closeOnConfirm: false
        })
        continue
      }
      if (row.__deleted && !row.__new) {
        request
          .delete(
            `/api/p/${row.int_id}/zeitmodellpzuord/${Datetime.fromISO(
              row.ab_datum
            ).toISO()}`
          )
          .end((err, response) => {
            dispatch(
              DeletePzuordSuccess(row.int_id, row.ab_datum, response.body)
              // UpdatePzuordSuccess(row.int_id, row.ab_datum, response.body)
            )
          })
        return
      }
      if (row.__new) {
        request
          .post(
            `/api/p/${row.int_id}/zeitmodellpzuord/${Datetime.fromISO(
              row.ab_datum
            ).toISO()}`
          )
          .send(row)
          .end((err, response) => {
            dispatch(
              UpdatePzuordSuccess(row.int_id, row.ab_datum, response.body)
            )
          })
        return
      }
      if (row.__dirty && !row.__new) {
        dispatch(UpdatePzuordStarted(row.int_id))
        request
          .put(`/api/p/${row.int_id}/zeitmodellpzuord`)
          .send(row)
          .end((err, response) => {
            dispatch(UpdatePzuordSuccess(row.int_id, response.body))
          })
        return
      }
    }
  }
}

export function LoadAbDatum(datum, query) {
  return (dispatch, getState) => {
    if (getState().AbDatum.datum == datum) {
      return
    }
    dispatch(LoadAbDatumStarted(datum))
    request
      .get(`/api/personalstamm/${Datetime.fromISO(datum).toISO()}`)
      .query(queryToString(query))
      .end((err, response) => {
        if (err) {
          dispatch(LoadAbDatumError(datum, err))
        } else {
          dispatch(LoadAbDatumSuccess(datum, response.body))
        }
      })
  }
}

export function LoadPstamm(int_id) {
  return (dispatch) => {
    dispatch(LoadPstammStarted(int_id))
    request.get(`/api/p/${int_id}/personalstamm`).end((err, response) => {
      if (err) {
        dispatch(LoadPstammError(int_id, err))
      } else {
        dispatch(LoadPstammSuccess(int_id, response.body))
      }
    })
  }
}

export function LoadPflicht() {
  return (dispatch) => {
    dispatch(LoadPflichtStarted())
    request.get("/api/pstamm/pflichtfelder").end((err, response) => {
      if (err) {
        dispatch(LoadPflichtError(err))
      } else {
        dispatch(LoadPflichtSuccess(response.body))
      }
    })
  }
}

const feldNamen = {
  pers_id: "Personalnummer",
  ausw_id: "Ausweisnummer",
  akad_gr_id: "Akademischer Grad",
  arb_erl_b: "Arbeitserlaubnis bis",
  aufent_bis: "Aufenthaltserlaubnis bis",
  bank_id: "Bankname",
  bank_kto: "IBAN Nummer",
  bank_lz: "BIC",
  beruf_gru: "Berufsgruppe",
  ein_dat_t: "Eintrittsdatum technisch",
  fa_ber_id: "Fachbereich",
  fam_stand: "Familienstand",
  funkt_id: "Funktion",
  oef_di_ab: "öffentlicher Dienst ab",
  sex: "Geschlecht",
  staat_an: "Staatsangehörigkeit",
  uk_bis: "unabkömmlich bis",
  zus_bez: "Zusatzbezeichnung",
  abr_grup: "Abrechnungsgruppe",
  anre_verh: "Anrechnungsverhältnis",
  anst_verh: "Anstellungsverhältnis",
  kal_kz: "Kalenderkennzeichen",
  tar_grp: "Tarifgruppe",
  zokr_1: "Zuordnungskriteriums 1",
  zokr_2: "Zuordnungskriteriums 2",
  zokr_3: "Zuordnungskriteriums 3",
  zokr_4: "Zuordnungskriteriums 4",
  zokr_5: "Zuordnungskriteriums 5",
  anrede_id: "Anrede",
  geb_name: "Geburtsname",
  haus_nr: "Hausnummer",
  land_id: "Bundesland",
  natio: "Nationalität",
  ort: "Wohnort",
  plz: "Postleitzahl",
  str: "Straße"
}

const feldNamenPhst = [
  "pers_id",
  // "ausw_id",
  "akad_gr_id",
  "beruf_gru",
  "funkt_id",
  "fa_ber_id",
  "zus_bez",
  "kal_kz",
  // "abr_grup",
  // "tar_grp",
  // "anst_verh",
  "fam_stand",
  "sex",
  "staat_an",
  "geb_name"
]
const feldNamenPhst2 = ["abr_grup", "tar_grp", "anst_verh"]

const defaultPflichtFelder = {
  fam_name: true,
  vor_name: true,
  geb_dat: true,
  sta_id: true,
  ein_dat: true,
  abr_kreis: true,
  land_id: true,
  natio: true,
  abt_id: true,
  kost_id: true
}

const feldNamenAdrs = ["anrede_id", "str", "haus_nr", "plz", "ort"]

export function CheckPflichtPages(felder, data, pflicht) {
  for (let feld of felder) {
    if (defaultPflichtFelder[feld] || pflicht[feld]) {
      if (data[feld] == null || data[feld] == "") {
        let feldName = feldNamen[feld] || feld
        return `Bitte ${feldName} angeben !`
      }
    }
  }
}

export function CheckPflichtPst(pstammData, pflicht) {
  // if (pstammData.pers_id == null || pstammData.pers_id == "") {
  if (pstammData.geb_dat == null) {
    return "bitte Geburtsdatum angeben"
  }
  if (pstammData.sta_id == "" || pstammData.sta_id == null) {
    return "bitte Status angeben"
  }
  if (pstammData.ein_dat == null) {
    return "bitte Eintrittsdatum angeben"
  }
  if (pstammData.abr_kreis == null) {
    return "bitte Abrechnungskreis angeben"
  }
  if (pstammData.natio == "" || pstammData.natio == null) {
    return "bitte Nation angeben"
  }
  if (pstammData.land_id == "" || pstammData.land_id == null) {
    return "bitte Bundesland angeben"
  }
  for (let key of feldNamenPhst) {
    //for (let [key, value] of Object.entries(pflicht)) {
    let value = pflicht[key]
    if (value === true) {
      if (pstammData[key] == "" || pstammData[key] == null) {
        let feldName = feldNamen[key] || key
        return `Bitte ${feldName} angeben !`
      }
    }
  }
  return null
}

export function CheckPflichtPst2(pstammData, pflicht) {
  for (let key of feldNamenPhst2) {
    let value = pflicht[key]
    if (value === true) {
      if (pstammData[key] == "" || pstammData[key] == null) {
        let feldName = feldNamen[key] || key
        return `Bitte ${feldName} angeben !`
      }
    }
  }
  return null
}

export function CheckNewAbko(abkostammData, abteilungskombi) {
  if (abkostammData.h_abt_id == "") {
    return "bitte Hauptabteilung angeben"
  }
  if (abkostammData.abt_id == "" || abkostammData.abt_id == null) {
    return "bitte Abteilung angeben"
  }
  if (abkostammData.u_abt_id == "") {
    return "bitte Unterabteilung angeben"
  }
  if (abkostammData.kost_id == "") {
    return "bitte Kostenstelle angeben"
  }
  return null
}

export function CheckAusweis(pstammData) {
  return request
    .get(`/api/pstamm/personalstammausweis/${pstammData.ausw_id}`)
    .query({
      abDatum: pstammData.ab_datum,
      bisDatum: pstammData.bis_dat,
      intID: pstammData.int_id
    })
}

export function CheckAustrittsDatum(pstammData) {
  if (pstammData.austr_dat == null) {
    return { end: (fn) => fn(null, { body: {} }) }
  }
  return request
    .get(
      `/api/dpl/basis/recordscheck/${Datetime.fromISO(
        pstammData.austr_dat
      ).toISO()}/${pstammData.int_id}`
    )
    .query({
      abDatum: pstammData.ab_datum,
      // austrDatum: pstammData.austr_dat,
      intID: pstammData.int_id
    })
}

export function UpdatePstamm(int_id, datum) {
  return (dispatch, getState) => {
    let pstammData = getState().Personalstamm.data[int_id][datum]
    if (!pstammData) {
      return
    }
    CheckAusweis(pstammData).end((err, response) => {
      if (err !== null && pstammData.ausw_id !== 0) {
        swal({
          title: "Hinweis zum Speichern der Personalstammdaten",
          text: response.body.Error,
          type: "warning",
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Ok!",
          closeOnConfirm: false
        })
        return
      }
      // Prüfen ob DPL-Planungen vorhanden
      CheckAustrittsDatum(pstammData).end((err, response) => {
        if (err !== null && pstammData.austr_dat !== null) {
          let doSave = false
          swal({
            title: "Bisherigen Planungen löschen ?",
            text: "Planungen nach dem Austrittsdatum müssen gelöscht werden !",
            icon: "warning",
            buttons: {
              doSave: {
                text: "Zukünftige Planung löschen",
                // value: false,
                value: true,
                visible: true,
                // visible: dosave,
                className: "",
                doSave: true,
                dosave: doSave,
                closeModal: true
              },
              cancel: {
                text: "Abbrechen",
                value: null,
                visible: true,
                className: "",
                closeModal: true
              }
            },
            dangerMode: true
          }).then((dosave) => {
            if (dosave == null) {
              return
            }
            if (dosave == true) {
              request
                .delete(
                  `/api/dpl/basis/recordsdelete/${Datetime.fromISO(
                    pstammData.austr_dat
                  ).toISO()}/${int_id}`
                )
                .end((err, response) => {
                  dispatch(DeleteSollRecordByIntIDSuccess(datum, int_id))
                })
            }
          })
        }

        let pflicht = getState().Personalstamm.pflicht
        err = CheckPflichtPst(pstammData, pflicht)
        if (err !== null) {
          if (pstammData.ausw_id !== 0) {
            swal({
              title: "Hinweis zum Speichern der Personalstammdaten",
              // text: err,
              text: response.body.Error,
              type: "warning",
              confirmButtonColor: "#DD6B55",
              confirmButtonText: "Ok!",
              closeOnConfirm: false
            })
            return
          }
        }
        if (pstammData.__new) {
          dispatch(UpdatePstammStarted(int_id, datum))
          request
            .post(
              `/api/p/${int_id}/personalstamm/${Datetime.fromISO(
                datum
              ).toISO()}`
            )
            .send(pstammData)
            .end((err, response) => {
              if (err) {
                dispatch(UpdatePstammError(int_id, datum, err))
              } else {
                dispatch(UpdatePstammSuccess(int_id, datum, response.body))
              }
            })
        }
        if (pstammData.__dirty) {
          dispatch(UpdatePstammStarted(int_id, datum))
          request
            .put(
              `/api/p/${int_id}/personalstamm/${Datetime.fromISO(
                datum
              ).toISO()}`
            )
            .send(pstammData)
            .end((err, response) => {
              if (err) {
                dispatch(UpdatePstammError(int_id, datum, err))
              } else {
                dispatch(UpdatePstammSuccess(int_id, datum, response.body))
              }
            })
        }
      })
    })
  }
}

export function LoadAdrsstamm(int_id) {
  return (dispatch, getState) => {
    let data = getState().Personalstamm.adrsdata[int_id]
    if (data) {
      return
    }
    dispatch(LoadAdrsstammStarted(int_id))
    request.get(`/api/p/${int_id}/adrsstamm/1`).end((err, response) => {
      if (err) {
        dispatch(LoadAdrsstammError(int_id, err))
      } else {
        dispatch(LoadAdrsstammSuccess(int_id, response.body))
      }
    })
  }
}

export function CheckAdrs(adrsstammData, pflicht) {
  if (adrsstammData.fam_name == "" || adrsstammData.fam_name == null) {
    return "bitte Familiennamen angeben"
  } else if (adrsstammData.vor_name == "" || adrsstammData.vor_name == null) {
    return "bitte Vornamen angeben"
  }
  // for (let [key, value] of Object.entries(pflicht)) {
  for (let key of feldNamenAdrs) {
    let value = pflicht[key]
    if (value === true) {
      // if (pflicht[key] === true) {
      if (adrsstammData[key] == "" || adrsstammData[key] == null) {
        let feldName = feldNamen[key] || key
        return `Bitte ${feldName} angeben`
      }
    }
  }
  return null
}
export function UpdateAdrsstamm(int_id) {
  return (dispatch, getState) => {
    let adrsstammData = getState().Personalstamm.adrsdata[int_id]
    if (!adrsstammData) {
      return
    }
    let pflicht = getState().Personalstamm.pflicht
    var err = CheckAdrs(adrsstammData, pflicht)
    if (err !== null) {
      swal({
        title: "Hinweis zum Speichern der Personalstammdaten",
        text: err,
        type: "warning",
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Ok!",
        closeOnConfirm: false
      })
      // err = null
      return
    }
    if (adrsstammData.__dirty) {
      dispatch(UpdateAdrsstammStarted(int_id))
      request
        .put(`/api/p/${int_id}/adrsstamm/1`)
        .send(adrsstammData)
        .end((err, response) => {
          if (err) {
            dispatch(UpdateAdrsstammError(int_id, err))
          } else {
            dispatch(UpdateAdrsstammSuccess(int_id, response.body))
          }
        })
    }
  }
}

export function LoadAbkostamm(int_id, datum) {
  return (dispatch) => {
    if (!int_id || !datum) {
      return
    }

    dispatch(LoadAbkostammStarted(int_id, datum))
    request
      .get(`/api/p/${int_id}/abkostamm/${Datetime.fromISO(datum).toISO()}`)
      .end((err, response) => {
        if (err) {
          dispatch(LoadAbkostammError(int_id, err))
        } else {
          dispatch(LoadAbkostammSuccess(int_id, response.body))
        }
      })
  }
}
export function LoadPzuord(int_id) {
  return (dispatch, getState) => {
    let data = getState().Personalstamm.pzuorddata[int_id]
    if (data) {
      return
    }
    dispatch(LoadPzuordStarted(int_id))
    request.get(`/api/p/${int_id}/zeitmodellpzuord`).end((err, response) => {
      if (err) {
        dispatch(LoadPzuordError(int_id, err))
      } else {
        dispatch(LoadPzuordSuccess(int_id, response.body))
      }
    })
  }
}
export function LoadPzuordVonBisStarted(int_id, sdatum, edatum) {
  return {
    type: LOAD_PZUORDVONBIS,
    status: STARTED,
    int_id,
    sdatum,
    edatum
  }
}
export function LoadPzuordVonBisSuccess(int_id, sdatum, edatum, data) {
  return {
    type: LOAD_PZUORDVONBIS,
    status: SUCCESS,
    int_id,
    sdatum,
    edatum,
    data
  }
}
export function LoadPzuordVonBisError(int_id, sdatum, edatum, error) {
  return {
    type: LOAD_PZUORDVONBIS,
    status: ERROR,
    int_id,
    sdatum,
    edatum,
    error
  }
}

export function LoadPzuordVonBis(int_id, sdatum, edatum) {
  return (dispatch) => {
    dispatch(LoadPzuordVonBisStarted(int_id, sdatum, edatum))
    request
      .get(
        `/api/p/${int_id}/modellpzuord/${Datetime.fromISO(
          sdatum
        ).toISO()}/${Datetime.fromISO(edatum).toISO()}`
      )
      .end((err, response) => {
        if (err) {
          dispatch(LoadPzuordVonBisError(int_id, sdatum, edatum, err))
        } else {
          dispatch(
            LoadPzuordVonBisSuccess(int_id, sdatum, edatum, response.body)
          )
        }
      })
  }
}

export function UpdatePzuordVonBisStarted(int_id, ab_datum) {
  return {
    type: UPDATE_PZUORDVONBIS,
    status: STARTED,
    int_id,
    ab_datum
  }
}
export function UpdatePzuordVonBisSuccess(int_id, ab_datum, data) {
  return {
    type: UPDATE_PZUORDVONBIS,
    status: SUCCESS,
    int_id,
    ab_datum,
    data
  }
}
function getDirtyRowspzuordvonbis(state) {
  let pzuordVonBis = state.Personalstamm.pzuordvonbis
  let result = []
  for (let rows of Object.values(pzuordVonBis)) {
    for (let row of Object.values(rows)) {
      if (row.__dirty || row.__new || row.__deleted) {
        result.push(row)
      }
    }
  }
  return result
}
export function UpdatePzuordVonBis() {
  return (dispatch, getState) => {
    let pzuordVonBis = getDirtyRowspzuordvonbis(getState())
    for (let row of pzuordVonBis) {
      // if (row.__deleted && !row.__new) {
      if (row.zm_tg == null && row.__dirty && !row.__new) {
        dispatch(UpdatePzuordVonBisStarted(row.int_id, row.ab_datum))
        request
          .delete(
            `/api/p/${row.int_id}/zeitmodellpzuord/${Datetime.fromISO(
              row.ab_datum
            ).toISO()}`
          )
          .end((err, response) => {
            dispatch(
              UpdatePzuordVonBisSuccess(row.int_id, row.ab_datum, response.body)
            )
            // }
          })
        return
      }
      if (row.__new) {
        request
          .post(
            `/api/p/${row.int_id}/zeitmodellpzuord/${Datetime.fromISO(
              row.ab_datum
            ).toISO()}`
          )
          .send(row)
          .end((err, response) => {
            dispatch(
              UpdatePzuordVonBisSuccess(row.int_id, row.ab_datum, response.body)
            )
            // }
          })
        return
      }
      if (row.__dirty && !row.__new) {
        dispatch(UpdatePzuordVonBisStarted(row.int_id))
        request
          .put(`/api/p/${row.int_id}/zeitmodellpzuord`)
          .send(row)
          .end((err, response) => {
            dispatch(UpdatePzuordVonBisSuccess(row.int_id, response.body))
            // }
          })
        return
      }
    }
  }
}

export function LoadAbkoKomb(mand_id, firm_id) {
  return (dispatch) => {
    dispatch(LoadAbkoKombStarted(mand_id, firm_id))
    request.get(`/api/abkokomb/${mand_id}/${firm_id}`).end((err, response) => {
      if (err) {
        dispatch(LoadAbkoKombError(mand_id, firm_id, err))
      } else {
        dispatch(LoadAbkoKombSuccess(mand_id, firm_id, response.body))
      }
    })
  }
}

export function CheckAbko(abkostammData) {
  for (let row of abkostammData) {
    // console.log(row.l_nbr)
    // if (row.l_nbr == 1 && row.__trydeleted == true) {
    //   return "die erste Zuordnung des Personalstamm kann nur geändert und nicht gelöscht werden !"
    // }
    var abkostammData = abkostammData.filter((abkostammData) => {
      return !abkostammData.__deleted
    })
    var prz = abkostammData.reduce(
      (a, b) => {
        return { prz_kk_1: parseFloat(a.prz_kk_1) + parseFloat(b.prz_kk_1) }
      },
      { prz_kk_1: 0 }
    )
    if (prz.prz_kk_1 !== 100.0) {
      return (
        "bitte den Prozentsatz ( 100 % ) angeben ! (aktuell " +
        prz.prz_kk_1 +
        "%)"
      )
    }
    // console.log(prz.prz_kk_1)
    if (row.h_abt_id == "") {
      return "bitte Hauptabteilung angeben"
    }
    if (row.abt_id == "") {
      return "bitte Abteilung angeben"
    }
    if (row.u_abt_id == "") {
      return "bitte Unterabteilung angeben"
    }
    if (row.kost_id == "") {
      return "bitte Kostenstelle angeben"
    }
  }
  return null
}
export function UpdateAbkostamm(int_id, datum) {
  return (dispatch, getState) => {
    let ab_datum = getState().Personalstamm.datum
    let abkostammData = getState().Personalstamm.abkodata[int_id] || []
    abkostammData = abkostammData.filter((r) => {
      return r.ab_datum == ab_datum
    })
    if (abkostammData.length == 0) {
      return
    }
    var err = CheckAbko(abkostammData)
    if (err !== null) {
      swal({
        title: "Hinweis zum Speichern der Personalstammdaten",
        text: err,
        type: "warning",
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Ok!",
        closeOnConfirm: false
      })
      return
    }
    for (let row of abkostammData) {
      if (row.__deleted) {
        dispatch(DeleteAbkostammStarted(int_id, datum, row.l_nbr))
        request
          .delete(
            `/api/p/${int_id}/abkostamm/${Datetime.fromISO(datum).toISO()}/${
              row.l_nbr
            }`
          )
          .send(row)
          .end((err, response) => {
            if (err) {
              dispatch(DeleteAbkostammError(int_id, datum, row.l_nbr, err))
            } else {
              dispatch(
                DeleteAbkostammSuccess(int_id, datum, row.l_nbr, response.body)
              )
            }
          })
      }

      if (row.__dirty) {
        dispatch(UpdateAbkostammStarted(int_id, datum))
        request
          .put(`/api/p/${int_id}/abkostamm/${Datetime.fromISO(datum).toISO()}`)
          .send(row)
          .end((err, response) => {
            if (err) {
              dispatch(UpdateAbkostammError(int_id, datum, err))
            } else {
              dispatch(UpdateAbkostammSuccess(int_id, datum, response.body))
            }
          })
      }
      if (row.__new) {
        dispatch(UpdateAbkostammStarted(int_id, datum))
        request
          .post(`/api/p/${int_id}/abkostamm/${Datetime.fromISO(datum).toISO()}`)
          .send(row)
          .end((err, response) => {
            if (err) {
              dispatch(UpdateAbkostammError(int_id, datum, err))
            } else {
              dispatch(UpdateAbkostammSuccess(int_id, datum, response.body))
            }
          })
      }
    }
  }
}

export function LoadInfostamm(int_id) {
  return (dispatch, getState) => {
    let data = getState().Personalstamm.infodata[int_id]
    if (data) {
      return
    }
    dispatch(LoadInfostammStarted(int_id))
    //console.log("load")
    request.get(`/api/p/${int_id}/infostamm`).end((err, response) => {
      //console.log(response.body)
      if (err) {
        dispatch(LoadInfostammError(int_id, err))
      } else {
        dispatch(LoadInfostammSuccess(int_id, response.body))
      }
    })
  }
}
export function UpdateInfostamm(int_id) {
  return (dispatch, getState) => {
    let infostammData = getState().Personalstamm.infodata[int_id] || []
    for (let row of infostammData) {
      if (row.__new) {
        let { id, ...r } = row
        request
          .post(`/api/p/${int_id}/infostamm`)
          .send(r)
          .end((err, response) => {
            dispatch(UpdateInfostammSuccess(int_id, response.body))
          })
      } else if (row.__dirty) {
        dispatch(UpdateInfostammStarted(int_id))
        request
          .put(`/api/p/${int_id}/infostamm`)
          .send(row)
          .end((err, response) => {
            if (err) {
              dispatch(UpdateInfostammError(int_id, err))
            } else {
              dispatch(UpdateInfostammSuccess(int_id, response.body))
            }
          })
      }
    }
  }
}
export function LoadInfostammDefSuccess(data) {
  return {
    type: LOAD_INFOSTAMMDEF,
    status: SUCCESS,
    data
  }
}
export function LoadInfostammDef() {
  return (dispatch, getState) => {
    request.get("/api/infostamm/def").end(
      (err, response) => {
        dispatch(LoadInfostammDefSuccess(response.body))
      }
      // }
    )
  }
}

export function LoadGeklastamm(int_id) {
  return (dispatch, getState) => {
    let data = getState().Personalstamm.gekladata[int_id]
    if (data) {
      return
    }
    dispatch(LoadGeklastammStarted(int_id))
    request.get(`/api/p/${int_id}/geklastamm`).end((err, response) => {
      if (err) {
        dispatch(LoadGeklastammError(int_id, err))
      } else {
        dispatch(LoadGeklastammSuccess(int_id, response.body))
      }
    })
  }
}

function CheckGekla(gekladata) {
  if (gekladata.num_key == null) {
    return "zum speichern bitte die Gefahrenklasse angeben !"
  }
  if (
    gekladata.bis_dat &&
    Datetime.fromISO(gekladata.bis_dat) < Datetime.fromISO(gekladata.ab_datum)
  ) {
    return "Das Bis-Datum muss nach dem Ab-Datum liegen !"
  }
  return null
}
export function UpdateGeklastamm(int_id) {
  return (dispatch, getState) => {
    let geklastammData = getState().Personalstamm.gekladata[int_id] || []
    for (let row of geklastammData) {
      var err = CheckGekla(row)
      if (err !== null) {
        swal({
          title: "Hinweis",
          text: err,
          type: "warning",
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Ok!",
          closeOnConfirm: false
        })
        continue
      }
      if (row.__deleted) {
        dispatch(DeleteGeklastammStarted(int_id, row.num_key))
        request
          .delete(`/api/p/${row.int_id}/geklastamm/${row.num_key}`)
          .end((err, response) => {
            if (err) {
              dispatch(DeleteGeklastammError(int_id, row.num_key, err))
            } else {
              dispatch(
                DeleteGeklastammSuccess(int_id, row.num_key, response.body)
              )
            }
          })
      }
      if (row.__new) {
        dispatch(InsertGeklastammStarted(int_id))
        request
          .post(`/api/p/${int_id}/geklastamm`)
          .send(row)
          .end((err, response) => {
            if (err) {
              dispatch(InsertGeklastammError(int_id, err))
            } else {
              dispatch(InsertGeklastammSuccess(int_id, response.body))
            }
          })
      }
      if (row.__dirty) {
        dispatch(UpdateGeklastammStarted(int_id))
        request
          .put(`/api/p/${int_id}/geklastamm`)
          .send(row)
          .end((err, response) => {
            if (err) {
              dispatch(UpdateGeklastammError(int_id, err))
            } else {
              dispatch(UpdateGeklastammSuccess(int_id, response.body))
            }
          })
      }
    }
  }
}

// export function NewPersonalstamm(data) {
//   request
//     .post("/api/pstamm/new")
//     .send(data)
//     .end((err, response) => {
//       console.log(err)
//       console.log(response)
//       // hier neu angelegten Pstamm aufrufen / redirect
//     })
// }

export function NewPersonalstammError(error) {
  return {
    type: CREATE_NEW_AB_DATUM,
    status: ERROR,
    error
  }
}
export function NewPersonalstammSuccess(data) {
  return {
    type: CREATE_NEW_AB_DATUM,
    status: SUCCESS,
    data
  }
}
export function NewPersonalstamm(data) {
  return (dispatch) => {
    request
      .post("/api/pstamm/new")
      .send(data)
      .end((err, response) => {
        dispatch(ClearDropDowns())
        if (err) {
          dispatch(NewPersonalstammError(err))
        } else {
          dispatch(NewPersonalstammSuccess(response.body))
        }
      })
  }
}

export function CheckPzuord(pzuorddata, sperrdatum, nosperre) {
  if (pzuorddata.kz_ztmod == "") {
    return "bitte Zeitmodell-Kennzeichen angeben"
  }
  if (pzuorddata.zm_tg == "") {
    return "bitte Zeitmodell angeben"
  }
  if (
    pzuorddata.kz_ztmod == "S" ||
    pzuorddata.kz_ztmod == "V" ||
    pzuorddata.kz_ztmod == "B"
  ) {
    if (pzuorddata.woc_nr == 0 || pzuorddata.woc_nr == null) {
      return "bitte Wochennummer angeben"
    }
  }
  if (sperrdatum.datum !== "0001-01-01T00:00:00Z" && nosperre.int_id == 0) {
    if (pzuorddata.ab_datum < sperrdatum.datum) {
      return "Änderungen vor dem Sperrdatum nicht möglich !"
    }
  }
  return null
}

export function LoadQuali(int_id) {
  return (dispatch, getState) => {
    let data = getState().Personalstamm.qualidata[int_id]
    if (data) {
      return
    }
    dispatch(LoadQualiStarted(int_id))
    request.get(`/api/p/${int_id}/quali`).end((err, response) => {
      if (err) {
        dispatch(LoadQualiError(int_id, err))
      } else {
        dispatch(LoadQualiSuccess(int_id, response.body))
      }
    })
  }
}

function CheckQuali(quali) {
  if (quali.quali_id == 0) {
    return "Zum Speichern bitte Qualifikation angegeben !"
  }
  if (
    quali.bis_dat &&
    Datetime.fromISO(quali.bis_dat) < Datetime.fromISO(quali.ab_datum)
  ) {
    return "Das Bis-Datum muss nach dem Ab-Datum liegen !"
  }
  return null
}

export function UpdateQuali(int_id) {
  return (dispatch, getState) => {
    let qualiData = getState().Personalstamm.qualidata[int_id] || []
    for (let row of qualiData) {
      var err = CheckQuali(row)
      if (err !== null) {
        swal({
          title: "Hinweis zum Speichern der Personalstammdaten",
          text: err,
          type: "warning",
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Ok!",
          closeOnConfirm: false
        })
        continue
      }
      if (row.__deleted) {
        if (row.__new) {
          return
        }
        dispatch(DeleteQualiStarted(int_id))
        request
          .delete(`/api/p/${int_id}/quali/${row.id}`)
          .send(row)
          .end((err, response) => {
            if (err) {
              dispatch(DeleteQualiError(int_id, err))
            } else {
              dispatch(DeleteQualiSuccess(int_id, response.body))
            }
          })
        return
      }
      if (row.__new) {
        dispatch(InsertQualiStarted(int_id))
        var newrow = { ...row }
        delete newrow.id
        request
          .post(`/api/p/${int_id}/quali`)
          .send(newrow)
          .end((err, response) => {
            if (err) {
              dispatch(InsertQualiError(int_id, err))
            } else {
              dispatch(InsertQualiSuccess(int_id, response.body))
            }
          })
        return
      }
      if (row.__dirty) {
        dispatch(UpdateQualiStarted(int_id))
        request
          .put(`/api/p/${int_id}/quali`)
          .send(row)
          .end((err, response) => {
            if (err) {
              dispatch(UpdateQualiError(int_id, err))
            } else {
              dispatch(UpdateQualiSuccess(int_id, response.body))
            }
          })
        return
      }
    }
  }
}

export function LoadSbhistamm(int_id) {
  return (dispatch, getState) => {
    let data = getState().Personalstamm.sbhidata[int_id]
    if (data) {
      return
    }
    dispatch(LoadSbhistammStarted(int_id))
    request.get(`/api/p/${int_id}/sbhistamm`).end((err, response) => {
      if (err) {
        dispatch(LoadSbhistammError(int_id, err))
      } else {
        dispatch(LoadSbhistammSuccess(int_id, response.body))
      }
    })
  }
}

function CheckSbhi(sbhi) {
  // if (sbhi.ab_datum == null) {
  //   return "Zum Speichern bitte ein Ab-Datum angegeben"
  // }
  if ((sbhi.sbh_grd > 100, sbhi.sbh_grd < 1)) {
    return "Der Schwerbehinderungsgrad muss mindestens 1 % und nicht mehr als 100 % sein"
  }
  if (
    sbhi.bis_dat &&
    Datetime.fromISO(sbhi.bis_dat) < Datetime.fromISO(sbhi.ab_datum)
  ) {
    return "Das Bis-Datum muss nach dem Ab-Datum sein !"
  }
  if (sbhi.ausst_beh == "") {
    return "Bitte geben Sie die Ausstellungsbehörde an"
  }
  if (sbhi.akt_zchn == "") {
    return "Bitte geben Sie das Aktenzeichen an"
  }
  return null
}
export function UpdateSbhistamm(int_id) {
  return (dispatch, getState) => {
    let sbhistammData = getState().Personalstamm.sbhidata[int_id] || []
    for (let row of sbhistammData) {
      var err = CheckSbhi(row)
      if (err !== null) {
        swal({
          title: "Hinweis zum Speichern der Personalstammdaten",
          text: err,
          type: "warning",
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Ok!",
          closeOnConfirm: false
        })
        continue
      }
      if (row.__deleted) {
        dispatch(DeleteSbhistammStarted(int_id, row.ab_datum))
        request
          .delete(
            `/api/p/${int_id}/sbhistamm/${Datetime.fromISO(
              row.ab_datum
            ).toISO()}`
          )
          .send(row)
          .end((err, response) => {
            if (err) {
              dispatch(DeleteSbhistammError(int_id, row.ab_datum, err))
            } else {
              dispatch(
                DeleteSbhistammSuccess(int_id, row.ab_datum, response.body)
              )
            }
          })
      }
      if (row.__new) {
        dispatch(InsertSbhistammStarted(int_id, row.ab_datum))
        request
          .post(
            `/api/p/${int_id}/sbhistamm/${Datetime.fromISO(
              row.ab_datum
            ).toISO()}`
          )
          .send(row)
          .end((err, response) => {
            if (err) {
              dispatch(UpdateSbhistammError(int_id, row.ab_datum, err))
            } else {
              dispatch(
                UpdateSbhistammSuccess(int_id, row.ab_datum, response.body)
              )
            }
          })
      }
      if (row.__dirty) {
        dispatch(UpdateSbhistammStarted(int_id))
        request
          .put(`/api/p/${int_id}/sbhistamm`)
          .send(row)
          .end((err, response) => {
            if (err) {
              dispatch(UpdateSbhistammError(int_id, err))
            } else {
              dispatch(UpdateSbhistammSuccess(int_id, response.body))
            }
          })
      }
    }
  }
}

export function LoadAktestamm(int_id) {
  return (dispatch, getState) => {
    let data = getState().Personalstamm.aktedata[int_id]
    if (data) {
      return
    }
    dispatch(LoadAktestammStarted(int_id))
    request.get(`/api/p/${int_id}/aktestamm`).end((err, response) => {
      if (err) {
        dispatch(LoadAktestammError(int_id, err))
      } else {
        dispatch(LoadAktestammSuccess(int_id, response.body))
      }
    })
  }
}
export function UpdateAktestamm(int_id) {
  return (dispatch, getState) => {
    let aktestammData = getState().Personalstamm.aktedata[int_id] || []
    for (let row of aktestammData) {
      if (row.__new) {
        let { doc_id, ...nrow } = row
        dispatch(InsertAktestammStarted(int_id))
        request
          .post(`/api/p/${int_id}/aktestamm`)
          .send(nrow)
          .end((err, response) => {
            if (err) {
              dispatch(InsertAktestammError(int_id, err))
            } else {
              dispatch(InsertAktestammSuccess(int_id, response.body))
            }
          })
        return
      }
      if (row.__dirty) {
        dispatch(UpdateAktestammStarted(int_id))
        request
          .put(`/api/p/${int_id}/aktestamm`)
          .send(row)
          .end((err, response) => {
            if (err) {
              dispatch(UpdateAktestammError(int_id, err))
            } else {
              dispatch(UpdateAktestammSuccess(int_id, response.body))
            }
          })
      }
    }
  }
}
